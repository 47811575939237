import React from 'react';
import Preloader from "../../../../utils/preloader/index";
import {getDropdownSelected} from "../../../../utils/utils";
import Dropdown from "../../../../utils/dropdown/dropdown";

import './index.css';
import LessonTypeContent from "./type-content";
import API from "../../../../utils/api";

class LessonModal extends React.Component {
    constructor (props) {
        super();

        const data = props.lessonData;
        let type = data.type ? data.type : 'video';

        const courseData = props.courseData;
        const isOnlineCourse = !!courseData.meta.date_start;

        const typesList = [
            {label: 'видео', value: 'video'},
            {label: 'текст', value: 'text'},
            {label: 'презентация', value: 'pdf'}
        ];

        if (isOnlineCourse) {
            typesList.push({label: 'вебинар', value: 'webinar'})
        }
        else {
            if (type === 'webinar') {
                type = 'video;'
            }
        }

        const content = data.content ? data.content : {
            is_cloud: data.is_cloud,
            url: data.video_code
        };

        this.state = {
            loading: false,
            data: data,
            title: data.title,
            description: data.description,
            type: type,
            content: content,
            valid: false,
            typeContentState: {
                valid: true,
                changes: false
            },
            originalType: {
                type: type.slice(),
                content: JSON.parse(JSON.stringify(content))
            },
            typesList: typesList,
            file: null,
            duration: data.duration || 0,
            originalDuration: data.duration ? parseInt(data.duration.toString()) : 0
        }
    }

    componentDidMount() {
        this.validate();
    };

    validate = () => {
        const t = this;
        const {type, title, description, typeContentState, duration, originalDuration} = t.state;
        const data = t.props.lessonData;

        const changes = title !== data.title
            || description !== data.description
            || duration !== originalDuration;

        let valid = title.length && description.length && duration && typeContentState.valid && (changes || typeContentState.changes);

        if (type === 'video') {
            valid = title.length && description.length && typeContentState.valid && (changes || typeContentState.changes);
        }

        t.setState({valid: valid});
    };

    handleChange = (e) => {
        const t = this;
        const obj = {};

        obj[e.target.name] = e.target.value;

        t.setState(obj, t.validate);
    };

    handleVideoUpload = () => {
        const t = this;
        const {type, title, file} = t.state;

        if (type === 'video' && file) {
            const {lessonData, courseId, uploadFunc} = t.props;

            const fileData = {
                file: file,
                lesson_id: lessonData.id,
                lesson_title: title,
                course_id: courseId
            };

            uploadFunc(fileData);
            t.setState({file: null});
        }
    };

    handleSave = (cb) => {
        const t = this;
        const p = t.props;
        const {type, title, description, content, duration} = t.state;
        const data = p.lessonData;

        const lessons = p.courseData.lessons;

        const lastOrder = lessons[lessons.length - 1].order

        const d = {
            type: type,
            title: title,
            description: description,
            content: content,
            duration: duration,
            order: lastOrder + 1,
        };

        t.setState({loading: true});

        if (data.id) {
            d.lesson_id = data.id;

            t.updateLesson(d, (r) =>{
                t.setState({loading: false});
                const updated = Object.assign(data, d);

                delete updated.lesson_id;

                p.onSave(updated.id, updated);

                t.handleVideoUpload();
                console.log(r)
            });
        }
        else {
            t.createLesson(d, (r) => {
                t.setState({loading: false});
                d.id = r.data.id;

                p.onSave(r.data.id, d);

                t.handleVideoUpload();
            });
        }
    };

    createLesson = (d, cb) => {
        const t = this;
        const {courseId} = t.props;

        API.post('/whub/v3/lesson/' + courseId, d)
            .then(cb)
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    updateLesson = (d, cb) => {
        const t = this;
        const {courseId} = t.props;

        API.put('/whub/v3/lesson/' + courseId, d)
            .then(cb)
            .catch(function (error) {
                t.setState({loading: false});
                alert(error)
            });
    };

    onTypeChange = (d) => {
        const t = this;
        const {originalType} = t.state;

        const type = d.data.value;
        const typeBlanks = {
            pdf: {url: ''},
            text: {text: ''},
            video: {url: '', is_cloud: false},
            webinar: {url: '', date: '', platform: 'zoom'}
        };

        const content = type === originalType.type ? originalType.content : typeBlanks[type];

        t.setState({type: type, content: content, duration: 0}, t.validate);
    };

    updateTypeContent = (data) => {
        const d = {
            content: data.content,
            typeContentState: data.state
        };

        if (data.file) {
            d.file = data.file;
        }

        this.setState(d, this.validate);
    };

    updateDuration = (seconds) => {
        const t = this;
        t.setState({duration: Math.ceil(seconds/60)}, t.validate);
    };

    render() {
        const t = this;
        const p = t.props;
        const {data, type, title, description, loading, typesList, content, valid, duration} = t.state;

        const typeSelected = getDropdownSelected(typesList, type);

        const preloader = loading ? <Preloader/> : '';

        const durationHints = {
            video: <div className="course_form_hint">если вы запустите превью видеоролика - поле заполнится автоматически!</div>,
            video_upload: <div className="course_form_hint">укажите продолжительность видеоурока</div>,
            text: <div className="course_form_hint">укажите примерное время, которое потребуется ученику на прохождение этого урока</div>,
            pdf: <div className="course_form_hint">укажите примерное время, которое потребуется ученику на прохождение этого урока</div>
        };

        const cls_duration_hidden = type === 'video' ? 'hidden' : '';
        // const cls_duration_hidden = '';

        return (
            <div className="course_edit_modal cem_lesson">
                <div className="cem_inner">
                    <dl className="z_up">
                        <dt>Тип урока:</dt>
                        <dd>
                            <Dropdown onChange={t.onTypeChange} name="type" className="lesson_type_select" selected={typeSelected} ddArr={typesList} />
                        </dd>
                    </dl>
                    <dl>
                        <dt>Заголовок:</dt>
                        <dd>
                            <input onChange={t.handleChange} value={title} name="title" />
                        </dd>
                    </dl>
                    <dl>
                        <dt>Описание:</dt>
                        <dd>
                            <textarea onChange={t.handleChange} value={description} name="description" />
                        </dd>
                    </dl>

                    <dl className={cls_duration_hidden}>
                        <dt className="duration_label">Продолжительность (в минутах):</dt>
                        <dd>
                            <input onChange={t.handleChange} value={duration} name="duration" />
                            {durationHints[type] ? durationHints[type] : ''}
                        </dd>
                    </dl>

                    <LessonTypeContent {...p} type={type} data={data} content={content} updateDuration={t.updateDuration} updateTypeContent={t.updateTypeContent} />

                    <div className="buttons_holder">
                        <dl>
                            <dt> </dt>
                            <dd>
                                <button onClick={t.handleSave} disabled={!valid}>Сохранить</button>
                            </dd>
                        </dl>
                    </div>
                </div>

                {preloader}
            </div>
        );
    }
}

export default LessonModal;
