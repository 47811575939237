import React from "react";
import API from "../../utils/api";
import {serrializer} from "../../utils/utils";
import { Link } from 'react-router-dom';


import '../my-courses.css';
import PurchasedCourses from "./purchased";
import Favourites from "./favourites";
import ArchivedCourses from "./archived";
import Preloader from "../../utils/preloader";
import Entries from "./entries";

class StudentEducation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      loading: true,
      courses: [],
      progressed: [],
      finished: [],
    }
  }

  loadCourses = () => {
    const t = this;
    const ids = t.props.userData.purchased;
    const entry_ids = t.props.userData.subscriptions.courses;

    if (!ids) {
        t.setState({loading: false, no_courses: true});
    }
    else {
        API.get('/whub/v3/course?' + serrializer({showAllPurchased: true, limit: 50, include: ids.concat(entry_ids)}))
            .then(function (r) {
                const totalFound = r.data.total;

                const data = r.data.results;

                const progressed = data.filter(el => el.progress_percentage > 0 && el.progress_percentage < 100);
                const finished = data.filter(el => el.progress_percentage === 100);

                t.setState({loading: false, courses: data, progressed, finished, loadedLength: totalFound});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
  };

  componentDidMount() {
    this.loadCourses();
  }

  activeTab = (c) => {
    return c === this.props.tab ? 'education_active_tab' : ''
  }

  renderActive = () => {
    const currentTab = {
      'purchased': <PurchasedCourses {...this.props} key={this.state.courses} courses={this.state.courses}/>,
      'favourites': <Favourites {...this.props} />,
      'archived': <ArchivedCourses {...this.props} />,
      'entries': <Entries reloadEducationCourses={this.loadCourses} {...this.props} key={this.props.userData.subscriptions.courses}/>,
      'continue-education': <PurchasedCourses {...this.props} key={this.state.progressed} courses={this.state.progressed}/>,
      'finished': <PurchasedCourses {...this.props} key={this.state.finished} courses={this.state.finished}/>
    }

    if (this.state.loading) return false

    return currentTab[this.props.tab]
  }

  expandTab = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const preloader = this.state.loading ? <Preloader/> : '';

    return (<div className="student_education">
      <div onClick={this.expandTab} className={"student_education_tabs " + (this.state.expanded ? "expanded_tab" :  "") }>
      <Link to={'/user/purchased'} className={this.activeTab('purchased')}>
        <span className="student_education_tabs_tab">
          <span className="tab_name">Все</span> 
          <span className="courses_counter">{this.state.courses ? this.state.courses.length : "0"}</span>
        </span>
      </Link>
      <Link to={'/user/continue-education'} className={this.activeTab('continue-education')}>
        <span className="student_education_tabs_tab">
          <span className="tab_name">Продолжить обучение</span>
          <span className="courses_counter">{this.state.progressed ? this.state.progressed.length : "0"}</span>
        </span>
      </Link>
      <Link to={'/user/entries'} className={this.activeTab('entries')}>
        <span className="student_education_tabs_tab">
          <span className="tab_name">Мои записи</span>
          { this.props.userData.subscriptions.courses && this.props.userData.subscriptions.courses.length ?
            <span className="courses_counter">{this.props.userData.subscriptions.courses ? this.props.userData.subscriptions.courses.length : "0"}</span>
            : ""
          }
        </span>
      </Link>
      <Link to={'/user/favourites'} className={this.activeTab('favourites')}>
        <span className="student_education_tabs_tab">
          <span className="tab_name">Избранное</span>
          { this.props.userData.favourites && this.props.userData.favourites.length ?
            <span className="courses_counter">{this.props.userData.favourites ? this.props.userData.favourites.length : "0"}</span>
            :  ""
          }
        </span>
      </Link>
      <Link to={'/user/finished'} className={this.activeTab('finished')}>
        <span className="student_education_tabs_tab">
          <span className="tab_name">Пройденные</span>
          <span className="courses_counter">{this.state.finished ? this.state.finished.length : "0"}</span>
        </span>
      </Link>
      <Link to={'/user/archived'} className={this.activeTab('archived')}>
        <span className="student_education_tabs_tab">
          <span className="tab_name">Архив</span>
          <span className="courses_counter">{this.props.userData.archived_courses ? this.props.userData.archived_courses.length : "0"}</span>
        </span>
      </Link>
      </div>
      {this.renderActive()}
      <div className="main_preloader">
        {preloader}
      </div>
    </div>)
  }
}

export default StudentEducation;